import { Icon, PrimaryButton, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { ContentWrapper, Section } from "components/shared/styles/Layout"
import { Col2 } from "components/ui/Col2/Col2"
import { Layout } from "components/ui/Layout/Layout"
import { Link } from "components/ui/Link/Link"
import { JobList } from "components/views/LeverJobView/components/JobList/JobList"
import { Title1, Title3 } from "lib/ui/Typography/Typography"
import { LeverJob } from "types/leverJob"

const JobBody = styled.article`
  p {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
  }

  a {
    text-decoration: underline;
  }
  ul {
    margin-bottom: 20px;
    margin-left: 20px;

    li {
      font-size: ${({ theme }) => theme.fontSizes.md};
    }
  }
  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: calc(4 / 3);
    margin-top: 7px;
    margin-bottom: 1px;
  }
`

const cleanUpContent = (content: string): string => {
  const removeFromString = ["<div>&nbsp;</div>", "<div><br></div>", 'style="font-size: 16px"']
  const regexRules = removeFromString.join("|")
  return content
    .replace(new RegExp(`(${regexRules})`, "gi"), "")
    .replaceAll("<div>", "<p>")
    .replaceAll("</div>", "</p>")
}

const getListContent = (lists: { content: string; text: string }[]): string => {
  const listContent = lists.map((list) => {
    return `<h3>${list.text}</h3><ul>${cleanUpContent(list.content)}</ul>`
  })
  return listContent.join("")
}

interface LeverJobViewProps {
  job: LeverJob
}

export const LeverJobView = ({ job }: LeverJobViewProps): JSX.Element => {
  const {
    categories: { location, department, team },
    text,
    description,
    additional,
    applyUrl,
    lists,
  } = job

  const getArticleContent = (): string => {
    return cleanUpContent(description) + getListContent(lists) + cleanUpContent(additional)
  }

  return (
    <Layout>
      <VerticalSpacing size="lg" />
      <ContentWrapper>
        <Title1>{text}</Title1>
        <Title3>
          {department} - {team}
        </Title3>
        <SecondaryTitle3 color="secondary">{location}</SecondaryTitle3>
        <VerticalSpacing size="xl" />
        <Section>
          <Col2>
            <div />
            <div>
              <JobBody dangerouslySetInnerHTML={{ __html: getArticleContent() }} />
              <VerticalSpacing />
              <Link to={applyUrl}>
                <PrimaryButton rightIcon={<Icon name="arrowRight" />}>
                  Apply for this job
                </PrimaryButton>
              </Link>
            </div>
          </Col2>
        </Section>
        <Section>
          <JobList />
        </Section>
      </ContentWrapper>
    </Layout>
  )
}

const SecondaryTitle3 = styled(Title3)`
  margin-top: 0;
`
