import { graphql, useStaticQuery } from "gatsby"
import { LeverJob } from "../../../../../../types/leverJob"

interface QueryResult {
  allLever: {
    nodes: LeverJob[]
  }
}

export const useJobs = (): { jobs: LeverJob[] } => {
  const { allLever } = useStaticQuery<QueryResult>(graphql`
    query LeverJobs {
      allLever {
        nodes {
          lever_id
          text
          createdAt
          description
          applyUrl
          additional
          hostedUrl
          categories {
            department
            location
            team
          }
        }
      }
    }
  `)

  return { jobs: allLever.nodes }
}
