import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { LeverJobView } from "components/views/LeverJobView/LeverJobView"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { helmetJsonLdProp } from "react-schemaorg"
import { JobPosting } from "schema-dts"
import { ContentfulImage } from "types/contentful"
import { LeverJob } from "types/leverJob"

interface LeverJobProps {
  data: {
    metaImage?: ContentfulImage
    lever: LeverJob
  }
  location: WindowLocation
}

const LeverJobTemplate = ({ data, location }: LeverJobProps): JSX.Element => {
  const { metaImage, lever } = data

  const makeHelmetTitle = (rawTitle: string): string => {
    if (rawTitle.length > 60) {
      const helmetTitle = `${rawTitle.substring(0, 56)} ...`
      return helmetTitle
    }

    return rawTitle
  }
  const title = ` ${lever.text} - ${lever.categories.location}`
  const metaTitle = makeHelmetTitle(title)

  return (
    <>
      <Seo
        image={metaImage?.media.gatsbyImageData}
        pathname={location.pathname}
        title={metaTitle}
      />
      <Helmet
        script={[
          helmetJsonLdProp<JobPosting>({
            "@context": "https://schema.org",
            "@type": "JobPosting",
            datePosted: lever.createdAt,
            hiringOrganization: {
              "@type": "Organization",
              name: "Einride",
            },
            title: lever.text,
          }),
        ]}
      />
      <LeverJobView job={lever} />
    </>
  )
}

export const query = graphql`
  query LeverJobQuery($id: String!) {
    metaImage: contentfulImage(contentful_id: { eq: "6uVZnNnJSrqKlD7mYKGQSt" }) {
      ...ContentfulImage
    }
    lever(lever_id: { eq: $id }) {
      lever_id
      text
      createdAt
      description
      applyUrl
      additional
      hostedUrl
      categories {
        department
        location
        team
      }
      lists {
        content
        text
      }
    }
  }
`

export default LeverJobTemplate
