import styled from "@emotion/styled"
import { Col, Desktop, Mobile, Row } from "components/shared/styles/Layout"
import { ReactNode } from "react"

const TableItemWrapper = styled.div<{ isFirst: boolean | undefined }>`
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
  ${(props) => (props.isFirst ? "border-top: 1px solid #d8d8d8;" : "")}

  @media (max-width: 576px) {
    padding: 20px 10px;
  }
`

const StyledTableItem = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  margin-left: 20px;
`

interface TableItemAltProps {
  isFirst?: boolean
  children: [ReactNode, ReactNode, ReactNode]
}

export const TableItemAlt = ({
  isFirst,
  children: [col1, col2, icon],
}: TableItemAltProps): JSX.Element => {
  return (
    <TableItemWrapper isFirst={isFirst}>
      <Desktop>
        <Row>
          <Col width={1 / 2}>{col1}</Col>
          <Col width={1 / 2}>
            <StyledTableItem>
              {col2}
              {icon}
            </StyledTableItem>
          </Col>
        </Row>
      </Desktop>
      <Mobile>
        <StyledTableItem>
          <div>
            {col1}
            {col2}
          </div>
          <IconWrapper>{icon}</IconWrapper>
        </StyledTableItem>
      </Mobile>
    </TableItemWrapper>
  )
}
