import { Icon } from "@einride/ui"
import { HeadingWrapper, Section } from "components/shared/styles/Layout"
import { Link } from "components/ui/Link/Link"
import { TableItemAlt } from "components/ui/TableItemAlt/TableItemAlt"
import { useJobs } from "components/views/LeverJobView/components/JobList/hooks/useJobs"
import { Paragraph, Title2 } from "lib/ui/Typography/Typography"

export const JobList = (): JSX.Element => {
  const { jobs } = useJobs()
  return (
    <>
      <HeadingWrapper>
        <Title2>Other jobs</Title2>
      </HeadingWrapper>
      <Section>
        {jobs.map((job) => {
          return (
            <Link key={job.lever_id} to={`/careers/${job.lever_id}`}>
              <TableItemAlt>
                <Paragraph>{job.text}</Paragraph>
                <Paragraph>
                  {job.categories.department} - {job.categories.team} - {job.categories.location}
                </Paragraph>
                <Paragraph>
                  <Icon name="arrowRight" />
                </Paragraph>
              </TableItemAlt>
            </Link>
          )
        })}
      </Section>
    </>
  )
}
